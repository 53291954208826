<template>
    <div class="addvote">
        <!-- 步骤条 -->
        <div class="steps">
            <Steps :current="current">
                <Step title="基本内容"></Step>
                <Step title="选项管理"></Step>
                <Step title="规则配置"></Step>
                <Step title="高级设置"></Step>
            </Steps>
        </div>
        <!-- 主体内容 -->
        <div class="container">
            <!-- 左侧菜单 -->
            <Row class="left-list">
                <Menu theme="light" :active-name="current" @on-select="selectMenu">
                    <MenuItem :name="item.id" v-for="item in menuList" :key="item.id">{{ item.name }}</MenuItem>
                </Menu>
            </Row>

            <!-- 右侧表单 -->
            <div class="right-content">
                <keep-alive>
                    <component :is="componentId" :formValidate="formValidate" @nextStep="nextStep" @save="publish" :ref="componentId" @reject="reject"></component>
                </keep-alive>
            </div>
        </div>
        <!-- 驳回框 -->
        <LiefengModal title="驳回原因" :value="reasonModal" @input="reasonStatusFn">
            <template v-slot:contentarea>
                <Form :model="formValidate" :rules="reasonValidate" :label-colon="true" ref="reasonForm">
                    <FormItem prop="reason">
                        <Input :maxlength="50" v-model.trim="formValidate.reason"></Input>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="reasonStatusFn(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="reasonSubmit">保存</Button>
            </template>
        </LiefengModal>
        <Spin fix v-if="spinShow"></Spin>
    </div>
</template>

<script>
import { format } from "@/utils/formatTime.js"
import baseInfo from "@/views/voteother//childrens/publishBaseInfo"
import highSetting from "@/views/voteother/childrens/publishHighSetting"
import optionManage from "@/views/voteother/childrens/publishOptionManage"
import ruleConfig from "@/views/voteother/childrens/publishRuleConfig"
import LiefengModal from "@/components/LiefengModal"
export default {
    props: ["infoId", "menuCodeObj", "orgCode"],
    data() {
        return {
            spinShow: false,
            //标题
            title: "",
            //驳回框状态
            reasonModal: false,
            //驳回表单验证
            reasonValidate: {
                reason: [{ required: true, message: "请输入驳回原因", trigger: "blur" }],
            },
            //当前右侧表单数据
            formValidate: {
                voteId: "",
                title: "",
                source: "", //信息来源
                subtype: "", //信息类型
                appointmentPhone: "", //联系电话（预约号码）
                startTime: "", //开始时间
                subStartTime: "",
                endTime: "", //结束时间
                subEndTime: "",
                provinceCode: 44, //省编码
                cityCode: "", //城市编码
                areaCode: "", //区县编码
                streetCode: "", //街道编码
                projectCodeList: "", //社区编码
                city: "", //修改时地址
                content: "", //富文本

                voteItemSeq: "2", //排序规则
                voteItemSeqList: [], //排序规则列表

                voteItemRos: [
                    {
                        itemNum: 1, //序号
                        title: "", //标题
                        subtitle: "", //详情
                        coverImg: "",
                        converVideo: "",
                        seq: 1, //序号
                        modifier: parent.vue.loginInfo.userinfo.nickName,
                    },
                ], //投票选项的List对象

                voteType: "1", //投票方式是单选还是多选
                voteTypeList: [], //投票方式列表

                voteCycle: "1", //投票周期
                voteCycleList: [], //投票周期列表

                voteMaxItem: 1, //可为同一选项投几票(单选的规则)
                voteMaxAccount: 1, //每个账号可以投几票(单选)
                selectMaxItem: 1, //最多选择几个选项(多选的规则)
                selectMinItem: 1, //最少选择几个选项(多选的规则)

                voteItemName: "", //选手称呼
                buttonName: "", //按钮名称
                buttonUnit: "", //票数单位
                voteItemNameList: [], //选手称呼列表
                buttonNameList: [], //按钮名称
                buttonUnitList: [], //票数单位

                voteStartTime: "", //每天限制投票起始时间
                voteEndTime: "", //每天限制投票终止时间

                openDiscuss: "1", //	是否开启评论
                openDiscussList: [], //	是否开启评论列表
                reason: "", //驳回原因
                realNameAuth: "1", //实名认证
                realNameAuthList: [],
                recommend: "0", //是否推荐
                pushChannel: false,

                modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
                staffId: parent.vue.loginInfo.userinfo.id,
                status: 1,
                creatorId: parent.vue.loginInfo.userinfo.id,
            },
            //当前右侧组件ID
            componentId: "baseInfo",
            //步进器进度
            current: 0,
            //左侧菜单
            menuList: [
                {
                    id: 0,
                    name: "基本内容",
                },
                {
                    id: 1,
                    name: "选项管理",
                },
                {
                    id: 2,
                    name: "规则配置",
                },
                {
                    id: 3,
                    name: "高级设置",
                },
            ],
        }
    },
    watch: {
        current(newVal) {
            this.$emit("comSelectMenu", newVal)
        },
    },
    created() {
        this.spinShow = true
        //获取数据字典信息
        Promise.all([
            this.getDictionaries("VOTE_TYPE"),
            this.getDictionaries("VOTE_CYCLE"),
            this.getDictionaries("VOTE_ITEM_SEQ"),
            this.getDictionaries("VOTE_ITEM_NAME"),
            this.getDictionaries("VOTE_BUTTON_NAME"),
            this.getDictionaries("VOTE_BUTTON_UNIT"),
            this.getDictionaries("VOTE_DISCUSS"),
            this.getDictionaries("VOTE_REAL_NAME_AUTH"),
        ]).then(res => {
            this.formValidate.voteTypeList = res[0].dataList
            this.formValidate.voteCycleList = res[1].dataList
            this.formValidate.voteItemSeqList = res[2].dataList
            this.formValidate.voteItemNameList = res[3].dataList
            this.formValidate.buttonNameList = res[4].dataList
            this.formValidate.buttonUnitList = res[5].dataList
            this.formValidate.openDiscussList = res[6].dataList
            this.formValidate.realNameAuthList = res[7].dataList

            this.formValidate.voteCycleList.push({
                dictKey: "3",
                dictValue: "不限制/全程可投",
            })
        })
        //获取信息类型
        this.$get("/voteapi/api/pc/menu/getMenuParam", {
            columnCode: this.$core.getUrlParam("menuId") || "",
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
        }).then(res => {
            this.subtypeList = res
        })

        this.$get("/voteapi/api/pc/affiche/getAfficheInfo", {
            infoId: this.infoId,
        }).then(res => {
            if (res.code == 200) {
                let startTime = format(res.data.startTime, "yyyy-MM-dd HH:mm:ss")
                let endTime = format(res.data.endTime, "yyyy-MM-dd HH:mm:ss")
                res.data.startTime = startTime
                res.data.endTime = endTime
                for (let k in res.data) {
                    if (this.formValidate.hasOwnProperty(k)) {
                        this.formValidate[k] = res.data[k]
                    }
                }

                this.formValidate.pushChannel = this.formValidate.pushChannel === "4" ? true : false
                if (this.subtypeList && this.subtypeList.length) {
                    this.subtypeList.map((item, index) => {
                        if (this.formValidate.subtype == item.value) {
                            this.formValidate.subtype = item.label
                        }
                    })
                }

                this.$emit("modalStatusFn", this.formValidate.status)
                this.spinShow = false
            }
        })
        this.$get("/voteapi/api/pc/affiche/getAfficheInfoManager", {
            infoId: this.infoId,
        }).then(res => {
            if (res.code == 200) {
                this.formValidate.city = res.dataList[0]
            }
        })
        this.$get("/voteapi/api/pc/componentVote/getVoteInfo", {
            businessType: this.menuCodeObj.businessType,
            functionType: this.menuCodeObj.functionType,
            businessCode: this.infoId,
        }).then(res => {
            if (res.code == 200) {
                for (let k in res.data) {
                    if (this.formValidate.hasOwnProperty(k)) {
                        this.formValidate[k] = res.data[k]
                    }
                }
                this.formValidate["voteItemRos"] = res.data.voteItemVos
                this.formValidate["voteId"] = res.data.voteId
            }
        })
    },
    methods: {
        // 通过并加急发布
        passAndSend() {
            this.$Modal.confirm({
                title: "加急说明",
                content:
                    "<p>1.经审核提交发布的合格内容，在点击【加急】按钮后，该信息将通过【加急】通道，在15分钟内完成发布流程。</p><p> 2.根据市民政局主管部门的批准意见，【加急】发布的信息，仅限于疫情防控或类似需要紧急发布的信息，具体标准由审核人员参照批准意见加强管控。</p>",
                onOk: () => {
                    this.publish(1)
                },
            })
        },
        //驳回状态
        reasonStatusFn(status) {
            this.reasonModal = status
        },
        //点击发布
        publish(releaseWay) {
            this.$post("/voteapi/api/pc/affiche/emp/changeAffiche", {
                infoId: this.infoId,
                status: this.formValidate.status == "1" ? "3" : this.formValidate.status == "3" ? "4" : "5",
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: this.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                reason: this.formValidate.reason,
                releaseWay: releaseWay ? releaseWay : "",
                pushType: this.formValidate.pushType && this.formValidate.pushType[0] ? this.formValidate.pushType[0] : "",
                channels: this.formValidate.pushType && this.formValidate.pushType[0] == 3 ? this.formData.channels : "",
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "提交成功！",
                        })
                        this.$emit("pageChange", {
                            page: 1,
                            pageSize: 10,
                        })
                        this.$emit("messageStatusCount")
                        this.$emit("addChangeModalFn", false)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "提交失败！",
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "提交失败！",
                    })
                })
        },
        //点击驳回按钮
        reject() {
            this.reasonModal = true
        },
        //驳回提交
        reasonSubmit() {
            this.$refs.reasonForm.validate(res => {
                if (res) {
                    this.$post("/voteapi/api/pc/affiche/emp/changeAffiche", {
                        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        oemCode: parent.vue.oemInfo.oemCode,
                        businessType: this.menuCodeObj.businessType,
                        functionType: this.menuCodeObj.functionType,
                        infoId: this.infoId,
                        status: 2,
                        reason: this.formValidate.reason,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "驳回成功！",
                                })
                                this.$emit("pageChange", {
                                    page: 1,
                                    pageSize: 10,
                                })
                                //重新获取信息状态的数量
                                this.$emit("messageStatusCount")
                                this.$emit("addChangeModalFn", false)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: "驳回失败！",
                                })
                            }
                        })
                        .catch(err => {
                            this.$Message.error({
                                background: true,
                                content: "驳回失败！",
                            })
                        })
                }
            })
        },
        getDictionaries(type) {
            //获取数据字典数据
            return this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: type,
            })
        },
        //步进器上下一步
        nextStep(value) {
            this.selectMenu(this.current + value)
        },
        selectMenu(name) {
            this.current = name
            switch (name) {
                case 0:
                    this.componentId = "baseInfo"
                    break
                case 1:
                    this.componentId = "optionManage"
                    break
                case 2:
                    this.componentId = "ruleConfig"
                    break
                default:
                    this.componentId = "highSetting"
            }
        },
    },
    components: {
        baseInfo,
        highSetting,
        optionManage,
        ruleConfig,
        LiefengModal,
    },
}
</script>

<style scoped lang='less'>
.addvote {
    background: #fcfcfc;
    border: 1px solid transparent;
    text-align: left;

    .steps {
        height: 80px;
        margin: 20px 50px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .ivu-steps {
            width: 80%;
        }
        .ivu-steps-status-process,
        .ivu-steps-status-finish {
            /deep/.ivu-steps-head-inner {
                border-color: #12b26d;
                background-color: #12b26d;
            }
        }
        /deep/.ivu-steps-icon {
            color: #fff !important;
        }
        /deep/.ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
            background: #12b26d;
        }
        /deep/.ivu-steps-status-error .ivu-steps-icon {
            color: #ed4014;
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin: 0 50px;
        min-height: calc(100vh - 222px);
        .ivu-menu-item {
            color: #303133;
            background: #fff;
        }
        .ivu-menu-item:hover {
            color: #409eff;
        }
        .ivu-menu-item-selected.ivu-menu-item-active {
            background: #fff;
            color: #409eff;
        }
        .left-list {
            height: 100%;
            width: 220px;
        }
        /deep/.ivu-menu {
            text-align: center;
            width: 220px !important;
            background: #fcfcfc;
            li {
                background: #fcfcfc;
            }
            li,
            .ivu-menu-item-selected:hover,
            li:hover {
                color: #12b26d;
            }
            .ivu-menu-item-selected {
                color: #12b26d;
            }
        }
        .ivu-menu-vertical.ivu-menu-light:after {
            width: 2px;
        }
        .ivu-menu-item-active:not(.ivu-menu-submenu):after {
            background: #12b26d;
        }
        .right-content {
            flex: 1;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            background: #fff;
            height: calc(100vh - 222px);
            padding: 20px;
        }
    }
}
</style>
